import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PurchaseService } from '../../services/purchase.service';
import { BaseComponentDirective } from '../../base/base.component';
import { EMPTY, Subject } from 'rxjs';
import { Purchase } from '../models/purchase.model';
import { NotificationService } from '../../services/notification.service';
import { take, catchError, tap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PurchaseFilter } from '../models/purchase-filter.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { LookupService } from '../../services/lookup.service';
import { AuthService } from '../../core/auth-service';
import { RoleGuardService } from '../../role-guard.service';
import { PagerService } from '../../pager/services/pager.service';
import { MigrationService } from '../../services/migration.service';
import { RoutingService } from '../../services/routing-service.service';

@Component({
  selector: 'app-purchase-review',
  templateUrl: './purchase-review.component.html',
  styleUrls: ['./purchase-review.component.scss']
})
export class PurchaseReviewComponent extends BaseComponentDirective implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dropdownMenuButton', { read: ElementRef, static: true }) filterButton: ElementRef;

  loading: boolean;
  filterChanged: boolean;
  purchases: Array<Purchase>;
  resultCount: number;
  screenWidth: number;
  pageCount: number;
  filters: PurchaseFilter;
  statuses: Array<KeyValuePair>;
  canMigratePurchases: boolean;
  migrating = new Array<number>();

  destroy$ = new Subject();
  initLoading: boolean;
  showFiltersOnLoad: boolean = false;
  viewReady: boolean = false;

  constructor(private purchaseService: PurchaseService,
     private lookupService: LookupService,
     private notifications: NotificationService,
     private router: Router,
     private roleGuardService: RoleGuardService,
     private pagerService: PagerService,
     private migrationService: MigrationService,
     private routingService: RoutingService) {
    super();
  }

  ngOnInit() {
    this.initLoading = true;

    this.routingService.redirectToAuthTaskIfMobile();
    this.checkMigrationRole();
    this.lookupService.getAppointmentOutcomes$().pipe(
      tap(result => {
        this.statuses = result;
        this.statuses.splice(0, 0, new KeyValuePair(null, 'All'));

        const savedFilters = JSON.parse(localStorage.getItem('PurchaseReviewFilters'));
        const now = new Date();
        if (savedFilters && savedFilters.filterExpiry > now.getTime()) {
          this.filters = savedFilters;
          this.retrieveData();
        } else {
          this.setDefaultFilterCriteria();
          this.showFiltersOnLoad = true;
          this.showFilters();
        }
      }),
      catchError(err => {
          this.loading = false;
          this.notifications.dangerToast('Failed to retrieve outcomes', err);
          return EMPTY;
      }),
      take(1)
    ).subscribe();

    this.pagerService.getPageNumber().pipe(
      catchError(() => {
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
    .subscribe((result) => {
      if (this.filters && result !== this.filters.pageNumber) {
        this.goTo(result);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewReady = true;
      this.showFilters();
    }, 500);
  }

  showFilters() {
    if (this.filterButton && this.viewReady && this.showFiltersOnLoad) {
      this.filterButton.nativeElement.click();
    }
  }

  checkMigrationRole() {
    this.canMigratePurchases = this.roleGuardService.hasRole(AuthService.migratePurchases);
  }

  setDefaultFilterCriteria() {
    const searchStart = new Date();
    searchStart.setHours(0, 0, 0, 0);

    this.filters = new PurchaseFilter();
    this.filters.dateFrom = searchStart;
    this.filters.maxResults = 10;
    this.filters.pageNumber = 1;
    this.filters.outcome = null;
    this.statusFilterChanged();
    this.filterChanged = false;

    this.pagerService.setPageNumber(1);
  }

  statusFilterChanged() {
    const selected = this.statuses.filter(x => x.value === this.filters.outcomeDesc);
    if (selected.length > 0) {
      this.filters.outcome = selected[0].key;
    }
    this.filterChange();
  }

  filterChange() {
    this.filterChanged = true;
  }

  search($event) {
    this.filterChanged = false;
    this.filters.pageNumber = 1;
    this.pagerService.setPageNumber(1);

    this.setFilterExpiry();
    this.retrieveData();

    const parent = this.findDropdownParent($event.srcElement);
    if (parent) {
      parent.classList.remove('show');
    }
  }

  findDropdownParent(element) {
    const parent = element.parentElement;
    if (parent.className.indexOf('dropdown-menu') !== -1) {
      return parent;
    } else {
      return this.findDropdownParent(parent);
    }
  }

  goTo(pageNumber: number) {
    this.filters.pageNumber = pageNumber;
    this.retrieveData();
  }

  setFilterExpiry() {
    const expiry = new Date();
    expiry.setHours(expiry.getHours() + 1);
    this.filters.filterExpiry = expiry.getTime();

    localStorage.setItem('PurchaseReviewFilters', JSON.stringify(this.filters));
  }

  retrieveData() {
    this.initLoading = false;
    this.loading = true;
    this.purchaseService.searchPurchases$(this.filters).pipe(
      tap(result => {
        this.loading = false;
        this.purchases = result.results;
        this.resultCount = result.totalResultCount;
        if (this.resultCount > this.filters.maxResults) {
          this.pageCount = Math.ceil(this.resultCount / this.filters.maxResults);
        } else {
          this.pageCount = 1;
        }
      }),
      catchError(err => {
        this.loading = false;
        this.notifications.dangerToast('Failed to retrieve purchases', err);
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    ).subscribe();
  }

  viewData(purchase: Purchase) {
    this.router.navigate(['/summary', purchase.quoteStateId]);
  }

  continuePurchase(purchase: Purchase) {
    this.router.navigate(['/purchase', purchase.quoteStateId]);
  }

  get pages() {
    const pageNumbers = new Array<number>();
    if (this.pageCount > 1) {
      for (let i = this.filters.pageNumber - 2; i <= this.filters.pageNumber + 2; i++) {
        if (i > 0 && i <= this.pageCount) {
          pageNumbers.push(i);
        }
      }
    }
    return pageNumbers;
  }

  get canPrevious() {
    return this.filters.pageNumber > 1;
  }

  get canNext() {
    return this.filters.pageNumber < this.pageCount;
  }

  migrate(quoteStateId: number) {
    this.migrating.push(quoteStateId);
    this.migrationService.resubmit$(quoteStateId).pipe(
      tap(() => {
        this.notifications.successToast('Purchase message sent');
        this.migrationFinished(quoteStateId);
      }),
      catchError(err => {
        this.migrationFinished(quoteStateId);
        this.notifications.dangerToast(
          'Failed to send purchase message',
          err
        );
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  migrationFinished(quoteStateId: number) {
    const index = this.migrating.indexOf(quoteStateId);
    if (index > -1) {
      this.migrating.splice(index, 1);
    }
  }

  migrationClass(quoteStateId: number): string {
    if (this.migrating.indexOf(quoteStateId) !== -1) {
      return 'loading';
    } else {
      return '';
    }
  }

  makeWrappable(text: string): string {
    if (text.length > 20 && text.indexOf(' ') === -1) {
      text = text.replaceAll('.', '. ');
    }
    return text;
  }
}
